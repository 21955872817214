export default class EntidadeService {
  constructor(http) {
    this._http = http
  }

  async getListaEntidades() {
    const { data } = await this._http.get(`/api/entidades/list`)
    return data
  }

  async getEntidades(dados) {
    const { data } = await this._http.get(
      `/api/entidades/${dados.page}/${dados.rows}`,
    )
    return data
  }

  async saveEntidade(entidade) {
    if (entidade.id) {
      return await this._http.put('/api/entidades', entidade)
    } else {
      return await this._http.post('/api/entidades', entidade)
    }
  }

  async deleteEntidade(entidade) {
    await this._http.delete(`/api/entidades/${entidade.id}`)
  }

  async getEntidade(id) {
    const { data } = await this._http.get(`/api/entidades/${id}`)
    return data
  }

  async getEntidadesPorConsignatariaId(id) {
    const { data } = await this._http.get(`/api/entidades/consignataria/${id}`)
    return data
  }
}
